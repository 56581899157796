// Generated by Framer (2923eb5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import BlurGradient from "https://framerusercontent.com/modules/rofp3mS0LNY15cjhHzia/38VAsjA9XQxFqtnMmScT/BlurGradient_Prod.js";
const BlurGradientFonts = getFonts(BlurGradient);

const cycleOrder = ["w_tz8fwwc", "uajRqFhbR"];

const serializationHash = "framer-wGH2T"

const variantClassNames = {uajRqFhbR: "framer-v-1xv6n1c", w_tz8fwwc: "framer-v-1rlkv3a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Hide: "uajRqFhbR", Show: "w_tz8fwwc"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "w_tz8fwwc"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "w_tz8fwwc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "uajRqFhbR") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1rlkv3a", className, classNames)} data-framer-name={"Show"} layoutDependency={layoutDependency} layoutId={"w_tz8fwwc"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({uajRqFhbR: {"data-framer-name": "Hide"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<ComponentViewportProvider ><motion.div className={"framer-1ftggts-container"} data-framer-name={"Blur Gradient (Bottom)"} layoutDependency={layoutDependency} layoutId={"HmCgjRsx4-container"} name={"Blur Gradient (Bottom)"}><BlurGradient blur={32} borderRadius={"0px"} direction={"toBottom"} height={"100%"} id={"HmCgjRsx4"} layoutId={"HmCgjRsx4"} name={"Blur Gradient (Bottom)"} style={{height: "100%", width: "100%"}} transition={{delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wGH2T.framer-1pa1l2h, .framer-wGH2T .framer-1pa1l2h { display: block; }", ".framer-wGH2T.framer-1rlkv3a { height: 72px; overflow: hidden; position: relative; width: 780px; }", ".framer-wGH2T .framer-1ftggts-container { bottom: 0px; flex: none; height: 72px; left: 0px; pointer-events: none; position: absolute; right: 0px; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 780
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"uajRqFhbR":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerItSBDLNPs: React.ComponentType<Props> = withCSS(Component, css, "framer-wGH2T") as typeof Component;
export default FramerItSBDLNPs;

FramerItSBDLNPs.displayName = "Misc/Blur Gradient";

FramerItSBDLNPs.defaultProps = {height: 72, width: 780};

addPropertyControls(FramerItSBDLNPs, {variant: {options: ["w_tz8fwwc", "uajRqFhbR"], optionTitles: ["Show", "Hide"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerItSBDLNPs, [{explicitInter: true, fonts: []}, ...BlurGradientFonts], {supportsExplicitInterCodegen: true})